import React, { Component } from 'react'
import Api from '../utils/Api'
import Listing from '../utils/Listing'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class Coupons extends Component {
    constructor() {
        super()
        this.title = "Add Coupon"
        this.state = {
            coupons: [],
            headers: {
                headersText: ["Name", "Price Drop", "Drop in Percentage"],
                headersKey: ["name", "drop", "percentage"],
                headersType: [0, 0, 2] /* 0- TExt 1-Image 2-Custom Function */
            },
            percentage: false,
            name: '',
            drop: 10,
            code: '',
            dialogOpen: false,
            saving: false,
            showDeleteDialog: false
        }

    }
    componentDidMount() {
        this.api.axios.get("/api/coupons" + this.props.location.search)
            .then(coupons => this.setState({ coupons }))
    }
    add() {
        this.title = "Add Coupon"
        this.type = "/api/coupons/add"
        this.setState({ dialogOpen: true, name: '', code: '' })

    }
    edit(id) {
        this.title = "Edit Coupon"
        this.type = "/api/coupons/edit/" + id;
        const choosen = this.state.coupons.filter(x => x.id === id)[0];
        this.setState({ dialogOpen: true, percentage: choosen.percentage, drop: choosen.drop, name: choosen.name, code: choosen.code })
    }
    closeDialog = () => this.setState({ dialogOpen: false, showDeleteDialog: false })
    view(id) {

    }
    delete(id) {
        this.deleteId = id;
        this.setState({ showDeleteDialog: true })
    }
    save() {
        this.api.axios.post(this.type, {
            name: this.state.name,
            code: this.state.code,
            drop: this.state.drop,
            percentage: this.state.percentage
        })
            .then(resp => {
                var coupons = this.state.coupons.filter(x => x.id !== resp.id);
                coupons.push(resp);
                this.setState({ coupons })
                this.closeDialog();
                this.api.setState({ type: 'success', message: 'Coupon updated successfully.', open: true })
            })
    }
    render() {
        return (
            <div>
                <Api progress={(f) => this.api = f} />
                <Listing customFunction={(d) => d ? 'yes' : 'no'} headers={this.state.headers} list={this.state.coupons} search={this.props.location.search} name="Coupons"
                    add={() => this.add()} view={(id) => this.view(id)} delete={(id) => this.delete(id)} edit={(id) => this.edit(id)}
                />
                <Dialog fullScreen open={this.state.dialogOpen} onClose={this.closeDialog} TransitionComponent={Transition}>
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.closeDialog} aria-label="close">
                                <i className="fa fa-times"></i>
                            </IconButton>
                            <Typography variant="h6">{this.title}</Typography>

                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: 65, padding: 5 }}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.setState({ saving: true })
                            this.save();

                        }}>
                            <div className="form-group">
                                <label>Coupon name</label>
                                <input className="form-control" required type="text" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>Coupon code</label>
                                <input className="form-control" required type="text" value={this.state.code} onChange={e => this.setState({ code: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>Discount in percentage?</label>
                                <select className="form-control" value={this.state.percentage} onChange={e => this.setState({ percentage: e.target.value })}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Drop in price</label>
                                <input className="form-control" required type="number" value={this.state.drop} onChange={e => this.setState({ drop: e.target.value })} />
                            </div>
                            {this.state.saving ? <CircularProgress /> : <button className="btn btn-primary">Save</button>}

                        </form>
                    </div>
                </Dialog>
                <Dialog
                    open={this.state.showDeleteDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Delete Coupon?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Once you delete it cannot be undone.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            Disagree</Button>
                        <Button onClick={() => {
                            this.api.axios("/api/coupons/del/" + this.deleteId)
                                .then(d => {
                                    this.setState({ showDeleteDialog: false, coupons: this.state.coupons.filter(x => x.id !== this.deleteId) })
                                    this.api.setState({ type: 'success', message: 'Coupon deleted successfully.', open: true })
                                })
                                .catch(e => {
                                    this.setState({ showDeleteDialog: false })
                                    this.api.setState({ type: 'error', message: e.error, open: true })

                                })
                        }} color="secondary">
                            Agree</Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

import React, { Component } from 'react'
import Api from '../utils/Api'
import query from 'querystring'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default class Orders extends Component {
    constructor() {
        super()
        this.state = {
            orders: [],
            search: '',
            dialogOpen: false,
            cancelDialog: false,
            reason: '',
            shipping: false
        }
        this.info = { products: [] };
    }
    componentDidMount() {
        this.api.axios.get("/api/orders")
            .then(orders => this.setState({ orders }))
    }
    showInfo = (object) => {
        this.info = object;
        this.setState({ dialogOpen: true })
    }
    closeDialog = () => this.setState({ dialogOpen: false, shipping: false })
    render() {
        const items = ["primary", "secondary", "light", "danger"]
        return (

            <div>
                <Api progress={(f) => this.api = f} />
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Orders</h1>
                    <div className="p-2 row">
                        <input defaultValue={query.parse(this.props.location.search)["?q"] || this.search} onChange={(e) => this.search = e.target.value} placeholder="Search..." type="text" className="form-control col-sm-6" />&nbsp;
                        <button onClick={() => window.location = `?q=` + this.search} className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fa fa-search"></i>
                        </button>
                        &nbsp;
                        {this.props.location.search ?
                            <a href="?" className="d-sm-inline-block btn btn-danger shadow-sm">
                                <i className="fa fa-times"></i></a> : null}
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="right">Order By</TableCell>
                                <TableCell align="right">Order Cost</TableCell>
                                <TableCell align="right">Location</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Info</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.orders.map(o => <TableRow key={o.id}>
                                <TableCell align="left">{new Date(o.time).toString()}</TableCell>
                                <TableCell align="right">{o.name}</TableCell>
                                <TableCell align="right">रू {o.priceWithDiscount}</TableCell>
                                <TableCell align="right">{o.location}</TableCell>
                                <TableCell align="right"><div className={`badge badge-${items[o.status]}`}>{o.status === 0 ? "Pending" : o.status === 1 ? "Shipping" : o.status === 3 ? "Canceled" : "Delivered"}</div></TableCell>
                                <TableCell align="right">
                                    <button onClick={() => this.showInfo(o)} className="btn btn-primary">
                                        <i className="fa fa-info-circle"></i>
                                    </button>


                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>

                </TableContainer>

                <Dialog fullScreen open={this.state.dialogOpen} onClose={this.closeDialog} TransitionComponent={Transition}>
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.closeDialog} aria-label="close">
                                <i className="fa fa-times"></i>
                            </IconButton>
                            <Typography variant="h6">Order View</Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: 65, padding: 5, backgroundColor: '#e5e5e5' }}>

                        <div className="card p-2" style={{ margin: '25px' }}>
                            Order By : {this.info.name}<br />
                            Order Id : {this.info.id}<br />
                            User mail : {this.info.email}<br />
                            User Phone : {this.info.phone}<br />
                            Order Total : रू {this.info.priceWithDiscount}<br />
                            Notes : {this.info.notes}<br />
                            Status : {this.info.status === 0 ? 'Waiting for approval' : this.info.status === 1 ? 'Shipping' : this.info === 2 ? 'Delivered' : 'Canceled'}
                        </div>
                        <br />

                        <TableContainer component={Paper} style={{ padding: '20px' }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.info.products.map(s => <TableRow key={s.id}>
                                        <TableCell align="left">{s.name}</TableCell>
                                        <TableCell align="center">{s.quantity} {s.term}</TableCell>
                                        <TableCell align="right">रू{s.price}</TableCell>
                                    </TableRow>)}
                                    <TableRow>
                                        <TableCell align="left">Discount</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="right">-रू{this.info.discount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Total</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="right">रू{this.info.priceWithDiscount}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <div className="card p-2" style={{ margin: '25px' }}>
                            Location : {this.info.location}<br />
                            Order Date : {new Date(this.info.time).toString()}<br />
                        </div>
                        <br />
                        {/* <iframe src={`https://www.google.com/maps/@${String(this.info.locationPoint).split(":").join(",")},15z`}/> */}



                        {this.state.shipping ? <div className="offset-5"><CircularProgress /></div> :
                            <div className="offset-4">
                                <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/@${String(this.info.locationPoint).split(":").join(",")},15z`} className="btn btn-secondary">Show In Google Maps</a>
                            &nbsp;&nbsp;&nbsp;
                            {this.info.status === 0 ?
                                    <button onClick={() => {
                                        this.setState({ shipping: true })
                                        this.api.axios.get("/api/orders/order/" + this.info.id + "/shipping")
                                            .then(s => {
                                                this.setState({ dialogOpen: false, orders: [s, ...this.state.orders.filter(x => x.id !== this.info.id)] })
                                                this.api.setState({ type: 'success', message: 'Order Status Updated successfully !.', open: true })

                                            })
                                    }} className="btn btn-primary">Ship Order</button> : this.info.status === 1 ? <button onClick={
                                        () => {
                                            this.setState({ shipping: true })
                                            this.api.axios.get("/api/orders/order/" + this.info.id + "/delivered")
                                                .then(s => {
                                                    this.setState({ dialogOpen: false, orders: [s, ...this.state.orders.filter(x => x.id !== this.info.id)] })
                                                    this.api.setState({ type: 'success', message: 'Order Status Updated successfully !.', open: true })

                                                })
                                        }
                                    } className="btn btn-primary">Deliver Order</button> : null}
                            &nbsp;&nbsp;&nbsp;
                            {this.info.status === 0 ?
                                    <button onClick={() => this.setState({ cancelDialog: true, dialogOpen: false })} className="btn btn-danger">Cancel Order</button> : null}
                            </div>

                        }

                    </div>

                </Dialog>

                <Dialog
                    open={this.state.cancelDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Cancel Order?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Please provide a reason.</DialogContentText>
                        <input value={this.state.reason} onChange={(e) => this.setState({ reason: e.target.value })} className="form-control" type="text" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ cancelDialog: false })} color="primary">
                            Nope</Button>
                        <Button disabled={this.state.reason.trim() === ''} onClick={() => {
                            this.api.axios.post(`/api/orders/order/${this.info.id}/canceled`, {
                                reason: this.state.reason
                            })
                                .then(d => {
                                    this.setState({ reason: '', cancelDialog: false, orders: [d, ...this.state.orders.filter(x => x.id !== this.info.id)] })
                                    this.api.setState({ type: 'success', message: 'Order Canceled successfully !.', open: true })
                                })
                        }} color="secondary">
                            Yes</Button>
                    </DialogActions>
                </Dialog>

            </div >
        )
    }
}

import React, { Component } from 'react'
import Api from '../utils/Api'
import Listing from '../utils/Listing'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import GalleryPicker from '../utils/GalleryPicker'
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default class Categories extends Component {

    constructor() {
        super()
        this.title = "Add Category"
        this.state = {
            categories: [],
            headers: {
                headersText: ["Image", "Name"],
                headersKey: ["url", "name"],
                headersType: [1, 0] /* 0- TExt 1-Image 2-Custom Function */
            },
            picture: '',
            name: '',
            dialogOpen: false,
            saving: false,
            showDeleteDialog: false
        }
    }
    componentDidMount() {
        this.api.axios.get("/api/categories" + this.props.location.search)
            .then(categories => this.setState({ categories }))
    }
    addItem() {
        this.type = "/api/categories/add"
        this.title = "Add Category"
        this.setState({ dialogOpen: true, name: "", picture: "", saving: false })

    }
    delete(id) {
        this.deleteId = id;
        this.setState({ showDeleteDialog: true })
    }
    edit(id) {
        this.type = "/api/categories/edit/" + id
        this.title = "Edit Category"
        const choosen = this.state.categories.filter(x => x.id === id)[0];
        this.setState({ name: choosen.name, picture: choosen.url, dialogOpen: true, saving: false })
        // this.setState({ dialogOpen: true })

    }
    view(id) {

    }
    save() {
        var { name, picture } = this.state;
        var data = { name, url: picture }
        this.api.axios.post(this.type, data)
            .then(resp => {
                var categories = this.state.categories.filter(x => x.id !== resp.id);
                categories.push(resp);
                this.setState({ categories })
                this.closeDialog();
                this.api.setState({ type: 'success', message: 'Category updated successfully.', open: true })
            })
    }
    handleUpload(image) {
        if (/\.png|\.svg|\.gif|\.jpg|\.jpeg/.exec(image.name)) {
            const fd = new FormData();
            fd.append("image", image)
            this.api.axios.post("/api/products/upload", fd, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(d => this.setState({ picture: d.url }))
                .catch(e => console.log(e))
        }
    }
    closeDialog = () => this.setState({ dialogOpen: false, showDeleteDialog: false })
    render() {
        return (
            <div>
                <Api progress={(f) => this.api = f} />
                <Listing headers={this.state.headers} list={this.state.categories} search={this.props.location.search} name="Categories"
                    add={() => this.addItem()} view={(id) => this.view(id)} delete={(id) => this.delete(id)} edit={(id) => this.edit(id)}
                />

                <Dialog fullScreen open={this.state.dialogOpen} onClose={this.closeDialog} TransitionComponent={Transition}>
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.closeDialog} aria-label="close">
                                <i className="fa fa-times"></i>
                            </IconButton>
                            <Typography variant="h6">{this.title}</Typography>

                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: 65, padding: 5 }}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.setState({ saving: true })
                            this.save();

                        }}>
                            <div className="form-group">
                                <label>Name</label>
                                <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="form-control" type="text" required />
                            </div>
                            <div className="form-group">
                                Preview :
                            {this.state.picture === '' ? null : <div className="preview-box">
                                    <img style={{ position: 'relative' }} className="photo" src={this.state.picture} alt="hola" />
                                    <i onClick={() => {
                                        this.setState({ picture: '' })
                                    }} style={{ position: 'absolute' }} className="fa fa-times i-cross"></i>
                                </div>}
                            </div>
                            <div className="form-group">
                                <label>Category Image</label>
                                <input onChange={(e) => this.handleUpload(e.target.files[0])} type="file" class="form-control" />
                            </div>
                            <GalleryPicker selected={(e) => this.setState({ picture: e })} />
                            {this.state.saving ? <CircularProgress /> : <button disabled={this.state.picture === ''} className="btn btn-primary">Save</button>}
                        </form>
                    </div>
                </Dialog>
                <Dialog
                    open={this.state.showDeleteDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Delete Category?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Once you delete it cannot be undone.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            Disagree</Button>
                        <Button onClick={() => {
                            this.api.axios("/api/categories/del/" + this.deleteId)
                                .then(d => {
                                    this.setState({ showDeleteDialog: false, categories: this.state.categories.filter(x => x.id !== this.deleteId) })
                                    this.api.setState({ type: 'success', message: 'Category deleted successfully.', open: true })
                                })
                                .catch(e => {
                                    this.setState({ showDeleteDialog: false })
                                    this.api.setState({ type: 'error', message: e.error, open: true })

                                })
                        }} color="secondary">
                            Agree</Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

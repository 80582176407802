import React, { Component } from 'react'
import Api from '../utils/Api'

export default class Gallery extends Component {
    constructor() {
        super()
        this.state = {
            pictures: []
        }

    }
    componentDidMount() {
        this.api.axios.get("/api/admin/pictures")
            .then(pictures => this.setState({ pictures }))
    }
    render() {
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gallery</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <Api progress={(s) => this.api = s} />
                <div className="row">
                    {this.state.pictures.map((s, i) => {
                        return <div key={"wi" + i} className="preview-box">
                            <img className="photo" src={s} alt="hola" />
                            <i onClick={() => {
                                this.api.axios.post("/api/admin/remove/image", { url: s })
                                    .then(d => { })
                                    .catch(e => { })
                                this.setState({ pictures: this.state.pictures.filter(x => x !== s) })
                            }} style={{ position: 'absolute' }} className="fa fa-times i-cross"></i>
                        </div>
                    })}

                </div>
            </div>
        )
    }
}

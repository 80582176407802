import React, { Component } from 'react'
import ReactLoading from "react-loading";
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Axios {
    constructor(state) {
        this.state = state;
        this.axios = axios.create({
            baseURL: localStorage.getItem("base")
        })
        this.axios.interceptors.request.use((config) => {
            this.progress(true)
            try {
                config.headers['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem("token")).token
            }
            catch (e) { }
            return config;
        })
        this.axios.interceptors.response.use((r) => {
            this.progress(false);
            return r.data;
        }, (e) => {
            this.setState({ show: false })
            throw e.response.data;
        })
    }
    progress(show) {
        this.state({ show })
    }
    setState(set) {
        this.state(set);
    }

}
export default class Api extends Component {

    constructor(props) {
        super(props);
        this.api = new Axios((show) => this.setState(show));
        this.state = {
            show: false,
            message: 'hi',
            open: false,
            type: 'error'
        }
        props.progress(this.api)
    }



    show(show) {
        this.setState({ show })
    }

    render() {
        return (
            <div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={() => this.setState({ open: false })} >
                    <Alert severity={this.state.type}>{this.state.message}</Alert>
                </Snackbar>
                {this.state.show ? <div className="loading">
                    <ReactLoading className="center" type={"spinningBubbles"} color={"black"} />
                    <p className="center down">Please wait !</p>

                </div>
                    : this.props.content}
            </div>
        )
    }
}


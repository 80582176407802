import React from 'react';
import Guard from './components/Guard'
// import './admin.css'
import 'font-awesome/css/font-awesome.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Guard}>
        </Route>
      </Switch>

    </Router>
  );
}


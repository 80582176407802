import React, {Component} from 'react'

import {NavLink} from 'react-router-dom'

export default class LeftBar extends Component {
    render() {
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <NavLink to="/dash" className="brand-link">
                    <img src="/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3"
                         style={{opacity: '.8'}}/>
                    <span className="brand-text font-weight-light">DashBoard</span>
                </NavLink>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li className="nav-item">
                                <NavLink to="/dash" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fas fa-tachometer-alt"/>
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="nav-header">General</li>

                            <li className="nav-item">
                                <NavLink to="/categories" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fas fa-folder"/>
                                    <span>Categories</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/products" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fas fa-cubes"/>
                                    <span>Products</span>
                                </NavLink>
                            </li>
                            <li className="nav-header">Discount</li>
                            <li className="nav-item">
                                <NavLink to="/coupons" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fas fa-money"/>
                                    <span>Coupons</span>
                                </NavLink>
                            </li>
                            <li className="nav-header">Customers</li>

                            <li className="nav-item">
                                <NavLink to="/users" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fas fa-users"/>
                                    <span>Users</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/orders" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fas fa-shopping-bag"/>
                                    <span>Orders</span>
                                </NavLink>
                            </li>
                            <li className="nav-header">Others</li>

                            <li className="nav-item">
                                <NavLink to="/gallery" className="nav-link" activeClassName="active">
                                    <i className="nav-icon fa fa-picture-o"/>
                                    <span>Gallery</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <div onClick={() => {
                                    localStorage.clear("base")
                                    localStorage.clear("token")
                                    window.location = "?";
                                }} className="nav-link">
                                    <i className="nav-icon fa fa-sign-out"/>
                                    <span>Logout</span>
                                </div>
                            </li>

                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>
        )
    }
}

import React, { Component } from 'react'
import Api from '../utils/Api'
import Listing from '../utils/Listing'

export default class Users extends Component {
    constructor() {
        super()
        this.state = {
            users: [],
            headers: {
                headersText: ["Name", "Phone", "Email", "Orders"],
                headersKey: ["name", "phone", "email", "orders"],
                headersType: [0, 0, 0, 2] /* 0- TExt 1-Image 2-Custom Function */
            },
            picture: '',
            name: '',
            dialogOpen: false,
            saving: false,
            showDeleteDialog: false
        }
    }
    componentDidMount() {
        this.api.axios.get("/api/admin/users" + this.props.location.search)
            .then(users => this.setState({ users }))
    }
    custom(data) {
        return data.length;
    }
    view(id) {

    }
    delete(id) {
        this.api.setState({ open: true, type: 'error', message: 'User cannot be deleted.' })
    }
    edit(id) {
        
    }
    render() {
        return (
            <div>
                <Api progress={(f) => this.api = f} />
                <Listing customFunction={(data) => this.custom(data)} disableAdd="true" headers={this.state.headers} list={this.state.users} search={this.props.location.search} name="Users"
                    add={() => this.addItem()} view={(id) => this.view(id)} delete={(id) => this.delete(id)} edit={(id) => this.edit(id)}
                />
            </div>
        )
    }
}

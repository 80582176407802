import React, { Component } from 'react'
import axios from 'axios'
import Snacks from './Snacks';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domain: '',
            email: '',
            password: '',
            snackCallback: null,
            loading: false
        }
    }
    submit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        axios.post(this.state.domain + "/api/users/admin/login", {
            email: this.state.email,
            password: this.state.password
        })
            .then(data => {
                localStorage.setItem("base", this.state.domain)
                localStorage.setItem("token", JSON.stringify(data.data))
                this.setState({ loading: false })
                window.location = "?";
            })
            .catch(err => {
                if (err.response === undefined) {
                    this.state.snackCallback({ message: 'Invalid server.' })
                }
                else if (err.response.status === 401) {
                    this.state.snackCallback({ message: 'Username/Password not matched.' })
                }
                this.setState({ loading: false })
            })

    }

    render() {
        return (
            <div className="container">
                <Snacks props={c => this.setState({ snackCallback: c })}></Snacks>
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                            </div>
                                            <form className="user" onSubmit={this.submit}>
                                                <div className="form-group">
                                                    <input type="url" className="form-control form-control-user" onChange={(e) => this.setState({ domain: e.target.value })} value={this.state.domain} placeholder="Domain Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input required type="email" className="form-control form-control-user" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} aria-describedby="emailHelp" placeholder="Email" />
                                                </div>
                                                <div className="form-group">
                                                    <input required type="password" className="form-control form-control-user" onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password} placeholder="Password" />
                                                </div>
                                                {this.state.loading ? <CircularProgress /> : <button className="btn btn-primary btn-user btn-block">Login</button>}

                                                <hr />


                                            </form>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}



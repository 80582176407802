import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default class Snacks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            open: false,
            type: 'error'
        }
        props.props((m) => {
            this.setState({ message: m.message, type: m.type || 'error', open: true })
        })
    }
    handleClose = (event, reason) => {
        this.setState({ open: false });
    };
    render() {
        return (
            <div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} >
                    <Alert severity={this.state.type}>{this.state.message}</Alert>
                </Snackbar>
            </div>
        )
    }
}

import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Api from './Api';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default class Listing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showdialog: false,
            pictures: [],
            selected: ''
        }
    }
    componentDidMount() {
        this.api.axios.get("/api/admin/pictures")
            .then(pictures => this.setState({ pictures }))
    }
    closeDialog = () => this.setState({ showdialog: false })
    render() {
        return (
            <div style={{ margin: 5 }}>
                <Api progress={(api) => this.api = api} />
                <button className="btn btn-sm btn-primary" type="button" onClick={() => this.setState({ showdialog: true })}>Choose from Gallery</button>

                <Dialog
                    open={this.state.showdialog}
                    TransitionComponent={Transition}
                    fullScreen
                >
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.closeDialog} aria-label="close">
                                <i className="fa fa-times"></i>
                            </IconButton>
                            <Typography variant="h6">Choose a image</Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: 65, padding: 5 }}>
                        <div className="row">
                            {this.state.pictures.map(i => <div>
                                <img alt="hola" onClick={s => this.setState({ selected: i })} style={{ margin: 5, padding: 5 }} className={`photo ${this.state.selected === i ? 'selected-img' : null}`} src={i} />
                            </div>)}
                        </div>
                        <br />
                        <button onClick={() => {
                            this.setState({ showdialog: false })
                            this.props.selected(this.state.selected)
                        }} className="btn btn-primary offset-5">Select</button>

                    </div>


                </Dialog>



            </div>
        )
    }
}

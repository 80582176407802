import React, { Component } from 'react'
import query from 'querystring'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class Listing extends Component {

    render() {
        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{this.props.name}</h1>
                    <div className="p-2 row">
                        <input defaultValue={query.parse(this.props.search)["?q"] || this.search} onChange={(e) => this.search = e.target.value} placeholder="Search..." type="text" className="form-control col-sm-6" />&nbsp;
                        <button onClick={() => window.location = `?q=` + this.search} className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fa fa-search"></i></button>
                            &nbsp;
                            {this.props.search ?
                            <a href="?" className="d-sm-inline-block btn btn-danger shadow-sm">
                                <i className="fa fa-times"></i></a> : null}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.props.disableAdd ? null : <button onClick={this.props.add} className="d-sm-inline-block btn btn-primary shadow-sm"><i
                            className="fa fa-plus-circle"></i> Add {this.props.name}</button>}
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {this.props.headers.headersText.map((e, i) => <TableCell key={"head-" + i} scope="th" align={i ? 'right' : 'left'}>{e}</TableCell>)}
                                <TableCell align="right">Options</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {this.props.list.map((p, i) => {
                                return <TableRow key={p.id ? p.id : "s" + i}>
                                    {

                                        this.props.headers.headersKey.map((e, i) => <TableCell key={p.id + "-" + i} scope="td" align={i ? 'right' : 'left'}>
                                            {this.props.headers.headersType[i] === 1 ? <img alt={e} className="photo" src={p[e]} /> : this.props.headers.headersType[i] === 2 ? this.props.customFunction(p[e]) :  p[e]}
                                        </TableCell>)

                                    }
                                    <TableCell align="right">
                                            <i onClick={() => this.props.edit(p.id)} className="fa fa-edit"></i>
                                            &nbsp;&nbsp;
                                            <i onClick={() => this.props.delete(p.id)} className="fa fa-trash"></i>

                                    </TableCell>
                                </TableRow>
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        )
    }
}

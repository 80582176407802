import React, { Component } from 'react'
import Api from '../utils/Api'
import { Link } from 'react-router-dom'
export default class Dash extends Component {

    constructor() {
        super()
        this.state = {
            users: 'loading...',
            products: 'loading...',
            orders: 'loading...',
            income: 'loading...'
        }
    }

    componentDidMount() {
        this.api.axios.get("/api/admin/stats")
            .then(s => {
                const { users, products, orders, income } = s;
                this.setState({ users, products, orders, income })
            })

        // this.progress.progress(true);
    }
    render() {

        return (
            <div >
                <Api progress={(f) => this.api = f} />
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>


                <div className="row noLinkColor">
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Link to="/users">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold  text-uppercase mb-1">Users</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.users}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <Link to="/products">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-uppercase mb-1">Products</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.products}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-cubes fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Link to="/orders">

                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-uppercase mb-1">Orders</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.orders}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-shopping-basket fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">

                        <div className="card border-left-primary shadow h-100 py-2">
                            <Link to="/orders">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-uppercase mb-1">Estimated Income</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">रू {this.state.income}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-dollar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>




            </div>
        )
    }
}

import React, { Component } from 'react'
import Api from '../utils/Api'
import Listing from '../utils/Listing'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import GalleryPicker from '../utils/GalleryPicker'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox } from '@material-ui/core';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class Products extends Component {

    constructor() {
        super()
        this.search = ""
        this.deleteId = ""
        this.headers = {
            headersText: ["Image", "Name", "Category", "Price", "Stock"],
            headersKey: ["pictures", "name", "category", "newPrice", "stock"],
            headersType: [1, 0, 2, 0, 0] /* 0- TExt 1-Image 2-Custom Function */
        }
        this.type = "/api/products/add";
        this.state = {
            products: [],
            dialogOpen: false,
            name: '',
            category: '',
            isNeww: false,
            isTop: false,
            isPublished: true,
            description: '',
            stock: 20,
            change: 1,
            term: 'Piece',
            oldPrice: 0,
            newPrice: 0,
            pictures: [],
            saving: false,
            categories: [],
            showDeleteDialog: false

        }
        this.resetForms();

    }
    customFunction(id) {
        try {
            return this.state.categories.filter(x => x.id === id)[0].name

        }
        catch (e) {
            return "a"
        }
    }
    resetForms() {
    }
    componentDidMount() {
        this.api.axios.get("/api/categories")
            .then(categories => {
                this.api.axios.get("/api/products" + this.props.location.search)
                    .then(products => this.setState({ products, categories, category: categories[0].id }))

            })
    }
    openDialog = () => this.setState({ dialogOpen: true })
    closeDialog = () => this.setState({ dialogOpen: false, showDeleteDialog: false })


    edit(id) {
        this.title = "Edit Product"
        var choosen = this.state.products.filter(x => x.id === id)[0]
        this.type = "/api/products/edit/" + id;
        var { name, category, isNeww, isPublished, isTop, description, stock, change, term, oldPrice, newPrice, pictures } = choosen;
        this.setState({ name, category, isNeww, isPublished, isTop, description, stock, change, term, oldPrice, newPrice, pictures })
        this.openDialog();
    }
    delete(id) {
        this.deleteId = id;
        this.setState({ showDeleteDialog: true })

    }
    addItem() {

        this.title = "Add Product"
        this.setState({ dialogOpen: true, name: '', description: '', pictures: [] })

    }
    view(id) {
        console.log("view " + id)
    }
    handleUpload(image) {
        if (/\.png|\.svg|\.gif|\.jpg|\.jpeg/.exec(image.name)) {
            const fd = new FormData();
            fd.append("image", image)
            this.api.axios.post("/api/products/upload", fd, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(d => {
                    var pictures = this.state.pictures;
                    pictures.push(d.url);
                    this.setState({ pictures })

                })
                .catch(e => console.log(e))
        }
    }
    save() {
        var { name, category, isNeww, isPublished, isTop, description, stock, change, term, oldPrice, newPrice, pictures } = this.state;
        var data = { name, category, isNeww, isPublished, isTop, description, stock, change, term, oldPrice, newPrice, pictures }
        this.api.axios.post(this.type, data)
            .then(resp => {
                var products = this.state.products.filter(x => x.id !== resp.id);
                products.push(resp);
                this.setState({ products })
                this.closeDialog();
                this.api.setState({ type: 'success', message: 'Product updated successfully.', open: true })
            })
    }
    render() {
        return (
            <div>
                <Api progress={(f) => this.api = f} />
                <Listing headers={this.headers} list={this.state.products} search={this.props.location.search} name="Products"
                    customFunction={(e) => this.customFunction(e)} add={() => this.addItem()} view={this.view} delete={(id) => this.delete(id)} edit={(id) => this.edit(id)}
                />

                <Dialog fullScreen open={this.state.dialogOpen} onClose={this.closeDialog} TransitionComponent={Transition}>
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.closeDialog} aria-label="close">
                                <i className="fa fa-times"></i>
                            </IconButton>
                            <Typography variant="h6">{this.title}</Typography>

                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: 65, padding: 5 }}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.setState({ saving: true })
                            this.save();
                        }}>
                            <div className="form-group">
                                <label>Product Name</label>
                                <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} required type="text" className="form-control" />
                                <small id="emailHelp" className="form-text text-muted">Enter product Name.</small>
                            </div>
                            <div className="form-group">
                                <label>Product Category</label>
                                <select className="form-control" required value={this.state.category} onChange={(e) => this.setState({ category: e.target.value })}>
                                    {this.state.categories.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <FormControlLabel
                                    checked={this.state.isNeww}
                                    onChange={(e) => this.setState({ isNeww: e.target.checked })}
                                    control={<Checkbox name="checkedG" />}
                                    label="New?"
                                />
                                <FormControlLabel
                                    checked={this.state.isTop}
                                    onChange={(e) => this.setState({ isTop: e.target.checked })}
                                    control={<Checkbox name="checkedG" />}
                                    label="Top?"
                                />
                                <FormControlLabel
                                    checked={this.state.isPublished}
                                    onChange={(e) => this.setState({ isPublished: e.target.checked })}
                                    control={<Checkbox name="checkedG" />}
                                    label="Published?"
                                />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea required rows="4" className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })}></textarea>
                            </div>
                            <div className="form-group">
                                <label>Stock</label>
                                <input required type="number" className="form-control" value={this.state.stock} onChange={(e) => this.setState({ stock: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>Change in quantity</label>
                                <input required type="number" className="form-control" value={this.state.change} onChange={(e) => this.setState({ change: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>Quantity Name</label>
                                <input required type="text" className="form-control" value={this.state.term} onChange={(e) => this.setState({ term: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>Old Price</label>
                                <input required type="number" className="form-control" value={this.state.oldPrice} onChange={(e) => this.setState({ oldPrice: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>New Price</label>
                                <input required type="number" className="form-control" value={this.state.newPrice} onChange={(e) => this.setState({ newPrice: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label>Previews: </label>
                                <div className="row">
                                    {this.state.pictures.map((s, i) => {
                                        return <div key={"wi" + i} className="preview-box">
                                            <img style={{ position: 'relative' }} className="photo" src={s} alt="hola" />
                                            <i onClick={() => {
                                                this.setState({ pictures: this.state.pictures.filter(x => x !== s) })
                                            }} style={{ position: 'absolute' }} className="fa fa-times i-cross"></i>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Images</label>
                                <input onChange={(e) => this.handleUpload(e.target.files[0])} type="file" className="form-control" accept="image/*" />
                            </div>
                            <GalleryPicker selected={(e) => this.setState({ pictures: [...this.state.pictures, e] })} />
                            {this.state.saving ? <CircularProgress /> : <button disabled={this.state.pictures.length === 0} className="btn btn-primary">Save</button>}
                        </form>

                    </div>

                </Dialog>
                <Dialog
                    open={this.state.showDeleteDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Delete product?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Once you delete this cannot be undone.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            Disagree</Button>
                        <Button onClick={() => {
                            this.api.axios("/api/products/del/" + this.deleteId)
                                .then(d => {
                                    this.setState({ showDeleteDialog: false, products: this.state.products.filter(x => x.id !== this.deleteId) })
                                    this.api.setState({ type: 'success', message: 'Product deleted successfully.', open: true })

                                })
                        }} color="secondary">
                            Agree</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

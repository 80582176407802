import React, { Component } from 'react'
import LeftBar from './LeftBar'
import NavBar from './NavBar'
import Dash from './Dash'
import Products from './Products'
import Categories from './Categories'
import Coupons from './Coupons'
import Orders from './Orders'
import Users from './Users'
import {
    Route, Redirect
} from "react-router-dom";

import '../custom.css'
import Gallery from './Gallery'

export default class Dashboard extends Component {

    render() {

        return (
            <div id="wrapper">
                <LeftBar></LeftBar>
                <NavBar></NavBar>
                <div className="content-wrapper">
                    <div className="content">
                        <Route exact path={this.props.path}>
                            <Redirect to="/dash" />

                        </Route>
                        <Route exact path={`${this.props.path}dash`} component={Dash} />
                        <Route path={`${this.props.path}products`} component={Products} />
                        <Route path={`${this.props.path}users`} component={Users} />
                        <Route path={`${this.props.path}categories`} component={Categories} />
                        <Route path={`${this.props.path}coupons`} component={Coupons} />
                        <Route path={`${this.props.path}orders`} component={Orders} />
                        <Route path={`${this.props.path}gallery`} component={Gallery} />


                    </div>
                </div>
            </div>
        )
    }
}

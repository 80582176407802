import React, {Component} from 'react'

export default class NavBar extends Component {
    render() {
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"/>
                        </div>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                    <a href="index3.html" className="nav-link">Home</a>
                </li> */}

                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <div className="nav-link" data-toggle="dropdown">
                            <i className="far fa-bell"/>
                            <span className="badge badge-danger navbar-badge">3</span>
                        </div>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">

                            <div className="dropdown-item">
                                {/* Message Start */}
                                <div className="media">
                                    <img src="dist/img/user3-128x128.jpg" alt="User Avatar"
                                         className="img-size-50 img-circle mr-3"/>
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Nora Silvester
                                            <span className="float-right text-sm text-warning"><i
                                                className="fas fa-star"/></span>
                                        </h3>
                                        <p className="text-sm">The subject goes here</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"/> 4 Hours Ago
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-divider"/>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-th-large"/>
                        </div>
                    </li>
                </ul>
            </nav>

        )
    }
}

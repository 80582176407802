import React, { Component } from 'react'
import Login from './Login'
import Dashboard from './Dashboard'

export default class Guard extends Component {
    render() {

        var token = null;
        try {
            token = JSON.parse(localStorage.getItem("token"))
        }
        catch (e) {
        }
        
        return (
            <div>
                {token && localStorage.getItem("base") ? <Dashboard path={this.props.match.path} /> : < Login />}
            </div>
        )
    }
}



